.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 30px;
}

.buttons-1 {
  width: 88px;
  height: 30px;

  font-size: 14px;
}

.buttons-2 {
  height: 30px;
  font-size: 14px;
}

.customerdetails-inactive {
  font-size: 14px;
  box-sizing: border-box;
  margin-right: 20px;
  margin-top: 20px;
  float: left;
  width: 120px;
  height: 145px;
  background: #ffffff;
  border: 1px solid #faf7f7;
  border-radius: 4px;
  cursor: pointer;
}

.top {
  margin-top: 25px;
  box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
}
.mail-template button{
  text-transform: capitalize;
  font-family: MyriadPro,Serif;
}
.bottom {
  height: auto;
  margin-top: 16px;
  box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bottom .goal-stages .MuiPaper-root {
  display: flex;
  align-items: center;
  font-family: MyriadPro,Serif;
  color: #2a2f33;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  justify-content: center;
}

.header-title {
  display: flex;
  justify-content: space-between;
  font-family: MyriadPro,Serif;
  letter-spacing: 0.4px;
}

.header-title .Header-action {
  font-size: 14px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.header-title .Header-action .MuiSvgIcon-root {
  cursor: pointer;
}

.Header {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  height: 29px;
  width: 288px;
  font-size: 21px;
  margin-top: 0px;
  color: #2a2f33;
  text-transform: capitalize;
}

.bottom-heading {
  font-size: 20px;
  font-weight: 600;
  padding-left: 32px;
}

.goal-stages .customerdetails {
  font-size: 14px;
  box-sizing: border-box;
  margin-right: 20px;
  margin-top: 20px;
  float: left;
  width: 120px;
  height: 145px;
  background: #ffffff;
  border: 1px solid rgba(82, 83, 83, 0.5);
  border-radius: 4px;
  cursor: pointer;
}

.goal-stages .completed .checkCircle {
  position: absolute;
  color: #fff;
}

.goal-stages .awaiting .pending-icon-icon {
  position: absolute;
  color: #1b4f72;
  padding-right: 5px;
  padding-top: 5px;
}

.goal-stages .awaiting .pending-icon {
  display: flex;
  justify-content: right;
}

.goal-stages .completed .checkCircle-icon {
  display: flex;
  justify-content: right;
  margin: 5px;
}

.goal-stages .completed .checkCircle-text {
  display: flex;
  height: inherit;
  margin-top: -10px;
}

.goal-stages .awaiting .checkCircle-text {
  display: flex;
  height: inherit;
  margin-top: -10px;
}

.goal-stages .customerdetailsclicked {
  font-size: 14px;
  box-sizing: border-box;
  margin-right: 20px;
  margin-top: 20px;
  float: left;
  width: 120px;
  height: 145px;
  background-color: #1b4f72;
  border: 1px solid rgba(170, 172, 174, 0.5);
  border-radius: 4px;
}
.goal-stages .customerdetailsclicked.wathetBG{
  background-color: #99d1ed;
}
.goal-stages .customerdetailsclicked .MuiCardContent-root {
  color: #ffffff;
}

.goal-stages .completed,
.goal-stages .selected {
  font-size: 14px;
  box-sizing: border-box;
  margin-right: 20px;
  margin-top: 20px;
  float: left;
  width: 120px;
  height: 145px;
  background-color: #1b4f72;
  border: 1px solid rgba(170, 172, 174, 0.5);
  border-radius: 4px;
}

.goal-stages .completed.wathetBG,
.goal-stages .selected.wathetBG{
  background-color: #99d1ed;
}


.goal-stages .customerdetails:hover .MuiCardContent-root {
  color: #1b4f72;
}

.goal-stages .customerdetails:not(.completed):hover {
  background: #ffffff;
  border: 2px solid #1b4f72;
  border-radius: 4px;
}

.goal-stages .awaiting .pending-icon-icon:target,
.goal-stages .customerdetailsclicked .pending-icon-icon {
  color: #ffffff;
}

.goal-stages .completed .MuiCardContent-root {
  display: flex;
  align-items: center;
}
.goal-stages .completed:hover .MuiCardContent-root,
.goal-stages .completed .MuiCardContent-root,
.goal-stages .selected .MuiCardContent-root {
  color: #ffffff;
}
.goal-stages .awaiting .MuiCardContent-root {
  display: flex;
  align-items: center;
}

.businessevent {
  font-size: 14px;
  float: left;
  box-sizing: border-box;
  margin-top: 40px;
  width: 120px;
  height: 145px;
  background: #ffffff;
  border: 1px solid rgba(170, 172, 174, 0.5);
  border-radius: 4px;
}

.MuiPaper-root .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 0;
}

.cuslogo {
  height: 100px;
  width: 100px;
  background: #6a737b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  justify-content: center;
  color: #ffffff;
}

.progressbar-card {
  font-family: MyriadPro,Serif;
}

.progressbar-card .MuiCardContent-root {
  margin-left: 20px;
}

.progressbar-header {
  color: black;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.document-upload {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.document-upload .MuiGrid-root {
  box-shadow: none;
}

.bottom .goal-stages .completed {
  display: block;
}

.bottom .goal-stages .awaiting {
  display: block;
}

.bottom .goal-stages .disabled {
  color: #aaacae;
  cursor: none;
}

.bottom .goal-stages:hover .disabled {
  background: #ffffff;
}

.bottom .goal-stages:hover .disabled .MuiCardContent-root {
  color: #aaacae;
}
.mailSend-sec{
  padding-left:16px;
  padding-right: 16px;
}
.onHoldArea{
  position:absolute;
  background-color:rgba(255,255,255,0.8);
  width:100%;
  height:100%;
  z-index:1000;
  cursor:not-allowed;
  text-align:center;
  font-size:30px;
  padding-top:50px
}
.disable-class{
  color: grey;
  cursor: not-allowed;
}
.disable-card{
  pointer-events: none;
  color: grey !important;
}
 .disable-completed-card{
  pointer-events: none;
  color: grey !important;
  background-color: grey !important;
}