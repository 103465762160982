.statusactive {
  background-color: rgb(220 221 222 / 87%);
  color: #6a737b;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.finished {
  background-color: #d9eef9;
  color: #008cd2;
  height: 40px;
  width: 150px;
  padding: 6px;
}

.steady {
  background-color: #ffd9d8;
  color: #ec0a05;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.inprogress {
  background-color: #fdf7dc;
  color: #f1c40f;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.notStartedStatus{
  background-color: #fdf7dc;
  color: #515050;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.onTrackStatus{
  background-color: #fdf7dc;
  color: #56B146;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.atRiskStatus{
  background-color: #fdf7dc;
  color: #FF8A00;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.requireAttentionsStatus{
  background-color: #fdf7dc;
  color: red;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.onHoldStatus{
  background-color: #fdf7dc;
  color: #6A737B;
  height: 30px;
  width: 150px;
  padding: 6px;
}

.steadyStateStatus{
  background-color: #fdf7dc;
  color: #008CD2;
  height: 30px;
  width: 150px;
  padding: 6px;
}
