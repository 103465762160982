.main-container {
  display: grid;
  /* width: 1776px; */
  padding: 20px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgb(106 115 123 / 15%);
  border-radius: 8px;
}
.title-heading {
  font-size: 24px;
}
.top-header {
  padding: 15px;
  border-radius: 0px;
  background: #edf6fc;
  display: flex;
}
.admin-table input {
  padding: 8px;
  font-weight: normal;
  border: 1px solid #e4e5e6;
  margin-top: 6px;
  width: 90%;
}
.admin-table th{
    font-weight: 400;
  }
.admin-table th span{
  font-weight: 700;
}

.admin-table td {
  color: #6a737b;
  text-align: left;
  font-family: MyriadPro,Serif;
  letter-spacing: 0.2px;
  font-size: 14px;
}
.top-header h2 {
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2a2f33;
}
.maincard {
  display: grid;
  grid-template-columns: 1fr;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgb(106 115 123 / 15%);
}
.admin-dashboard-wrapper{
  padding: 20px;
}
.firstcard-div .MuiPaper-root {
  border: none;
  border-radius: 8px;
  box-shadow: none;
  margin-bottom: 0;
}
.EmailSearch {
  box-sizing: border-box;
  height: 32px;
  left: 0%;
  right: 0%;
  top: calc(50% - 32px / 2);
  background: #ffffff;
  border-radius: 16px;
  width: 340px;
}
.EmailSearch form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 2px 12px 0px 8px;
}
.EmailSearch .search-color {
  color: #1b4f72;
  font-size: 20px;
}
.EmailSearch .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
  border: none;
}
.form-control input::before {
  border: none;
}
.form-control::before {
  border: none;
}
.email-filters {
  display: flex;
}
.email-filters label {
  display: flex;
  /* position: absolute; */
  width: 64px;
  height: 32px;
  /* left: 0px; */
  /* top: 0px; */
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* display: flex; */
  align-items: center;
  color: #383838;
}
.email-filter-left {
  margin-right: 10px;
}
.email-filter-left,
.email-filter-right {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 240px;
  height: 32px;
  background: #ffffff;
  border-radius: 0px 3px 3px 0px;
}
.Search-result {
  background: #e4e5e6;
  padding: 10px;
  font-family: MyriadPro,Serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #6a737b;
}
.Email-table thead th {
  font-family: MyriadPro,Serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #6a737b;
  background: #fafafa;
  padding: 15px;
  vertical-align: middle;
}
/* .Email-table thead th::after{
    background: url(./src/assets/caret-down.png);
   
}  */
.Email-table thead th:last-child {
  padding-right: 17px;
  text-align: right;
}
.Email-table tbody tr td {
  padding: 12px 15px;
  vertical-align: middle;
  line-height: 15px;
}
.Email-table tbody tr th {
  padding: 12px 15px;
  vertical-align: middle;
  line-height: 15px;
}
.actions-icon {
  display: inline-flex;
  padding: 0;
  margin: 0;
}
.actions-icon li {
  display: inline-flex;
  list-style-type: none;
 margin-left: 7px;
}
.Action-icons {
  color: #aaacae;
  font-size: 20px !important;
}
.Expand-icons {
  position: absolute;
  top: 11px;
  font-size: 20px !important;
}
/*status-classes*/
.Status-archive {
  background: #f1f2f2;
  color: #f1f2f2;
}
.Status-InProgress {
  background: #d9eef9;
  color: #d9eef9;
}
.Status-Deleted {
  background: #ffd9d8;
  color: #ffd9d8;
}
.Status-waitingForreply {
  background: #fdf7dc;
  color: #fdf7dc;
}
.Status-Pause {
  background: #e9ebec;
  color: #e9ebec;
}
.table-pagination {
  display: flex;
  justify-content: center;
  margin: 10px;
}
