.View-document-popup {
    position: relative;
}
.View-document-popup .MuiSelect-select{
    padding: 8px;

}
.View-document-popup h2{
    font-family: MyriadPro,Serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #2a2f33;
    padding: 15px
}
.View-document-popup > h2 > button >svg{
    font-size:20px;
} 
.View-document-popup ul li{
    border: solid 1px #eee;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 1px 1px 4px rgb(106 115 123 / 15%);
}
.popup-container{padding:0px 20px 150px 20px;}
.popup-container .css-1e9lk82-MuiListItemAvatar-root{min-width: 30px;}
.popup-container .css-1e9lk82-MuiListItemAvatar-root svg{color:#008cd2;
    font-size: 22px;
    vertical-align: middle}
    
.popup-container .MuiButtonBase-root{padding: 8px;}
.popup-container .MuiButtonBase-root svg{font-size: 20px; vertical-align: middle;}
.upload-title{
    font-family: MyriadPro,Serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2a2f33;
    margin-bottom: 10px;
}
.View-document-popup .form-div {
    padding-right: 8px;
}
.View-document-popup .documentUploadClass .notfileUpload,
.View-document-popup .documentUploadClass .fileUpload {
  max-width: 100%;
  height: 125px;
  width: 360px;
}
.View-document-popup .form-buttons{
    border-top: solid 1px #E4E5E6;
    position: sticky;
}
.View-document-popup .form-buttons button{
    padding: 6px 20px;
    font-size: 16px;
}
.DocumentTable{
    padding: 10px 16px !important;
}
.DocumentTable table thead tr th{
    padding-left: 0px !important;
    color:#6A737B;
    padding-bottom: 8px;
}
.DocumentTable table tbody tr td,.DocumentTable table tbody tr th{
    padding-left: 0px !important;
    border-bottom: solid 1px #E4E5E6;
    font-size: 13px;
    color: #2A2F33;
   
}
.DocumentTable table button{
    padding-left: 0px;
}
.DocumentTable table button svg{
    font-size: 16px;
}
.UploadDocumentText{
    display: flex;
    margin-top: 20px;
}
.DocumentTable .input-select{
    margin-top: 0px !important;
    margin-left: 0px !important;
}
.DocumentTable  .upload-card-container{
    margin-left: 0px;
}
.DocumentTable .documentUploadClass {
    margin: 0px;
}
.DocumentTable hr{
    display: none;
}

.DocumentTable .button-wrapper{
    margin: 0px;
}
.DocumentTable .button-wrapper button{
    padding: 6px 20px !important;
    font-size: 16px !important;
    margin: 10px 0px 10px -10px !important;
}