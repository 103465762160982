.sidebar {
  height: 1012px;
  width: 69px;
  position: absolute;
  background-color: #fff;
  margin-left: -6px;
  border-right: 1px solid rgba(233, 235, 236, 0.65);
}
.top-container .MuiGrid-grid-xs-1 {
  max-width: 6.7%;
}
.top-container .MuiGrid-grid-xs-11 {
  max-width: 95.666667%;
}
.sidebar-open {
  height: 1012px;
  width: 236px;
  margin-left: -6px;
  background-color: #e9ebec;
  position: absolute;
  box-shadow: 6px 0px 8px rgba(106, 115, 123, 0.25);
  z-index: 999;
  transition: 1s;
}
.list {
  margin-top: 0px;
  margin-left: -39px;
  cursor: pointer;
}
.active {
  background: rgba(153, 209, 237, 0.35);
}
.active .item {
  position: relative;
}
.active .item:after {
  content: '';
  position: absolute;
  right: 9px;
  width: 2px;
  height: 60%;
  background: #1b4f72;
}
#icon svg {
  width: 0.8em;
}
.item {
  height: 64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 77px;
}
.active .item-open {
  background: rgba(153, 209, 237, 0.35);
}
.item-open {
  width: 236px;
  height: 64px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: 1s;
}
.item #title {
  visibility: hidden;
}
.item-open #title {
  /* width:99px; */
  height: 24px;
  margin-left: 15px;
  margin-top: 17px;
  color: #1b4f72;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  transition: 1s;
}
#icon {
  margin-left: 20px;
  color: #A1A1A1;
}
.active #icon{
  color: #1b4f72;
}
.item-open #icon {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-top: 13px;
}
.toggle-navbar {
  position: absolute;
  top: -42px;
  height: 32px;
  margin-left: 24px;
  color: white;
}
