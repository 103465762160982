.contain {
  display: flex;
  justify-content: flex-start;

  flex-direction: row;
}
.data {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 25px;
  color: #008cd2;
}
.dataconcierge {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
  margin-left: 25px;
  color: #008cd2;
}
.heading {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 25px;
}
.check {
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
  margin-left: 25px;
  color: #008cd2;
}

.table-container {
  padding: 0 20px;
}

.table-head-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-head-title {
  color : #2A2F33;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Myriad Pro';
}
.export-warpper {
  text-decoration: none;
}
.export-warpper > button {
  text-transform: none;
}
