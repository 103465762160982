.doc-list{
    margin-bottom: 20px;
  }
  .doc-list .MuiPaper-root{
    box-shadow: none !important;
    border: 1px solid #e4e5e6;
    border-top:none;
  }
  .doc-list .MuiTableCell-head{
    background-color: #fafafa !important;
    color: #6A737B !important;
    font-size: 12px;
    border-top: 1px solid #E4E5E6;
    padding:6px 16px !important;
  }
  .doc-list .MuiTableCell-body{
    font-size: 12px !important;
    color: #2a2f33 !important;
    padding:6px 16px !important;
  }
  .doc-list .MuiTableBody-root .MuiTableRow-root:hover{
    background-color: #edf6fc;
  }
  .doc-list .sls-icon{
    font-size:18px;
    color: #6A737B;
    margin-right: 10px;
  }