.charts-itme-content{
    position: relative;
}
.charts-title{
    position: absolute;
    color: #2A2F33;
    font-feature-settings: 'clig' off, 'liga' off;
    /* 14px Small Header */
    font-family: MyriadPro,Serif;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-left:10px;
    margin-top:8px;
    width: 220px;
}