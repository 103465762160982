.highlight{
    font-weight: 600;
    font-size:14px;
    color: #008CD2;
}
.MuiBox-root .skip-buttons-1 {
    width: 100%;
    color: #a1a1a1;
    border: 1px solid #a1a1a1;
    font-family: MyriadPro,Serif;
    text-transform: none;
    background-color: #fff;
    font-size: 1.125rem;
    line-height: 1.125rem;
    padding: 0.5rem 1rem;
    border-radius: 1.125rem;
  }
 .markbutton{
    
    height:30px;
  font-size: 14px;
  }