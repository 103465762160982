#primary {
  font-family: MyriadPro,Serif;
  text-transform: none;
  background-color: #008cd2;
  color: #fff;
  border: none;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 5px;
  /* width: 20%; */
  border-radius: 1.125rem;
}
#secondary{
  font-family: MyriadPro,Serif;
  text-transform: none;
  background-color: #fff;
  color: #008cd2;
  border: 1px solid #008cd2;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 5px;
  /* width: 20%; */
  border-radius: 1.125rem;
}

.content {
  display: flex;
}
.closeDialog {
  position: absolute;
  padding: 10px;
  cursor: pointer;
  right: 0px;
  color: #aaacae;
}
.modal-dialog-content {
  width: 600px;
  height: 768px;
  max-width: 588px;
  margin-top: 161px;
}
