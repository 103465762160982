.head-status-list h5{
    color: #2A2F33;
    font-family: MyriadPro,Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}
.head-status-list-tit{
    display: flex;
    justify-content: center;
    color: #2A2F33;
    font-family: MyriadPro,Serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.head-status-list-tit .head-status-list-tit-item{
    margin-left:6px;
    display: flex;
    align-items: center;
}
span.bg-pink,span.bg-orange,span.bg-green,span.bg-grey{
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 5px;
}
span.bg-pink{
    background-color: #FC7573;
}
span.bg-orange{
    background-color: #FFBC70;
}
span.bg-green{
    background-color: #A2D699;
}
span.bg-grey{
    background-color: #C4C7CB;
}
.body-status-list .status-list-head,.status-list-body .status-list-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E5E6;;
}
.status-list-head{
    font-weight: bold;
    color: #2A2F33;
    font-feature-settings: 'clig' off, 'liga' off;
    /* 14px Small Header */
    font-family: MyriadPro,Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #AAACAE;
    margin-left:16px;
    margin-right: 16px;
    margin-bottom:10px;
    margin-top:30px;
}
.status-list-body{
    flex: 1;
    margin-left:16px;
    margin-right: 16px;
}
.status-list-first-item,.status-list-second-item,.status-list-third-item{
    padding-top:10px;
    padding-bottom:10px;
}
.status-list-first-item{
    display: flex;
    align-items: center;
    width:200px;
    text-align: left;
}
.status-list-second-item{
    width: 180px;
}
.status-list-third-item{
    width:80px;
    text-align: right;
}
.status-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.body-status-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
}
