.ErrorMsg{
    background: none !important;
    box-shadow: none !important;
    text-align: center;
}
.ErrorMsg img{
    width: 230px;
    margin-bottom: 15px;
}
.ErrorMsg h2{
font-family: MyriadPro,Serif;
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height:52px;
text-align: center;
margin: 10px 0px;
color: #A1A1A1;
}
.ErrorMsg p{
 font-family: MyriadPro,Serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
margin-top: 20px;
color: #A1A1A1;

}