.Text_container{
    display:flex;
    gap:30px
}
.label{
    margin-top: 25px;
    margin-left: 25px;
    margin-bottom: 100px;
    font-weight: 400;
    font-size: 18px;
    width:75%;
}
.buttons-set{
    margin-top: 25px;
    margin-bottom: 0px;
    height:25px;
   display:flex;
   gap:10px
}

