.help-training{
    padding: 20px;
  }
  .help-training img{
    width: 100%;
  }
  .help-training .MuiTypography-body1{
    font-size: 20px;
    font-weight: 600;   
  }
  .help-training .MuiTypography-body2{
    font-size: 16px;
    font-weight: 400;
  }
  .help-training .MuiButtonBase-root{
    background-color: #007EBD;
    color: white;
    width: 100%;
    margin-top: 20px;           
  } 