/* .home {
  position: relative;
  margin-top: -4vh;
  margin-left: -4vh;
} */
.container {
  display: grid;
}

.cardLayout {
  margin-top: 5px;
  margin-left: 0%;
  margin-right: 0%;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: 1fr 2fr 1fr; */
  gap: 19px 19px;
}
.custom_detail {
  cursor: pointer;
  /* box-shadow: 1px 1px 4px 0px #6a737b26 !important; */
}

.cardLayout .custom_detail:hover {
  border: 2px solid #1B4F72;
  box-shadow: 2px 2px 8px 2px rgba(106, 115, 123, 0.15);
  border-radius: 8px;
  margin: 0px;
}
.cardLayout .custom_detail{
background: #FFFFFF;
box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
border-radius: 4px;
}

.custom_detail a {
  text-decoration: none;
}
.firstcard {
  display: grid;
}
.firstcard .MuiPaper-root {
  border: 1px dashed #aaacae;
  border-radius: 8px;
  box-shadow: none;
}

.firstcard .createCustomer:hover {
  color: #ffffff;
}
.firstcard .createCustomer {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #2A2F33;
  margin-top: 10px;
}
.firstcard:hover .createCustomer {
  color: #ffffff;
}
.default {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.default:hover {
  border: none;
  background-color: #1b4f72;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* .MuiGrid-container {
  padding: 0 20px;
} */

.title {
  position: relative;
  margin-left: 75px;
  margin-top: 20px;
}
/* .MuiGrid-item {
  box-shadow: 0 0 10px 0 rgb(42 47 51 / 10%);
  background: #ffffff;
  border-radius: 8px;
} */
.firstcard .add_circle_outline {
  color: #1b4f72;
  font-size: 30px;
}
.firstcard:hover .add_circle_outline {
  color: #ffffff;
}

.status {
  display: flex;
  margin-bottom: 10px;
}
.status-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #56b146;
  padding: 6px 10px;
  background: #e6f4e4;
  border-radius: 6px;
}
.status-icon {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.status-icon svg {
  position: absolute;
  right: -12px;
  top: -3px;
  color: #6a737b;
}

.customer-info {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.customer-logo {
  width: 100px;
  height: 100px;
  left: 0px;
  top: 0px;
  background: #1b4f72;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  justify-content: center;
}
.customer-logo img.logo{
  max-width: 100%;
  max-height: 100%;
}
.customer-detail {
  align-items: center;
  display: flex;
  margin-left: 16px;
  margin-bottom:5px;
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #2A2F33;
}

.cardLayout .custom_detail:hover .customer-detail,
.cardLayout .custom_detail:hover .customer-onboardDate{
  color: #1B4F72;
}
.customer-onboardDate {
  height: 22px;
  line-height: 19px;
  color: #a1a1a1;
  margin-left:16px;
}
.MuiLinearProgress-colorPrimary {
  margin: 20px 0px 0px 0px;
}
.assocImage {
  display: flex;
  margin: 20px 0;
}
.associate-Image {
  width: 30px;
  margin-left: 5px;
  border: 1px solid #008cd2;
  border-radius: 50%;
}
.customPagination .MuiButtonBase-root.Mui-selected,
.customPagination .MuiButtonBase-root.Mui-selected:hover {
  border: 1px solid #022f46;
  background-color: #008cd2;
  color: #ffffff;
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 600;
}
.css-46bh2p-MuiCardContent-root:hover {
  padding: 15px;
}

.Header_1 {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-top: 0px;
  color: #2a2f33;
}

.viewClickedStatus{
  color:#008CD2;
}
.chartContainer-dashboard{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.chart-center-top {
  display: flex;
  justify-content: space-between;
}
.chart-center-top .chart-center-top-item{
  width: 32.6%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  height: 120px;
}
.chart-left{
    width: 14.78%;
    border-radius: 8px; 
    box-shadow: 0 0 5px #ccc;
    background-color: #fff;
    padding-left:13px;
    padding-bottom:50px;
}
.chart-right{
  width: 23%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  margin-right: -10px;
}
.chart-center{
  width:60.6%;
  margin-left:0.8%;
  margin-right: 0.8%;
}
.chart-center-bottom-top-item{
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px #ccc;
  width:49.5%;
  height: 326px;
}
.chart-center-bottom-bottom,.chart-center-bottom-top{
  display: flex;
  justify-content: space-between;
  margin-top:8px;
}
.chart-center-bottom-bottom-item{
  width: 32.8%;
  height: 280px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px #ccc;
  overflow: hidden;
}
.chart-left .MuiGrid-container{
  display: block !important;
}
.chart-left .MuiGrid-container .MuiInputBase-root{
  width: 90%;
  margin-bottom:20px;
}
.filters-tit{
  font-size: 16px;
  color: #2A2F33;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top:20px;
}
.chart-left .MuiGrid-container .MuiGrid-root:last-child{
  display: flex;
  max-width: 90% !important;
  justify-content: end;
}
.chart-left .MuiGrid-container .MuiInputBase-root:last-child button{
  width: 120px !important;
}
.chart-center-top .chart-center-top-item{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.chart-center-top .chart-center-top-item .p-sub01{
  font-size: 14px;
  font-weight: bold;
  color: #2A2F33;
}
.chart-center-top .chart-center-top-item .p-sub02{
  font-size: 24px;
  font-weight: bold;
}
.chart-center-top .chart-center-top-item:first-child{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chart-center-top .chart-center-top-item:first-child .sub01,.chart-center-top .chart-center-top-item:first-child .sub02{
  width:140px;
}
.chart-center-top .chart-center-top-item:first-child .sub01 p.p-sub01,.chart-center-top .chart-center-top-item:first-child .sub02 p.p-sub01{
  font-size: 14px;
  font-weight: bold;
  color: #2A2F33;
  text-align: center;
}
.chart-center-top .chart-center-top-item:first-child .sub01 p.p-sub02,.chart-center-top .chart-center-top-item:first-child .sub02 p.p-sub02{
  font-size: 24px;
  font-weight: bold;
  color: #2A2F33;
  text-align: center;
}
.chart-center-top .chart-center-top-item:first-child .sub02 p.p-sub02{
  color: #C4C7CB;
}
.chart-center-top .lineSub{
  width: 1px;
  height: 62px;
  background-color: #E4E5E6;
}
.blueColor{
  color: #008CD2;
}
.pinkColor{
  color: #FFBC70;
}
.charts-itme-content,.recharts-wrapper{
  position: absolute !important;
}
.recharts-text{
  font-size: 10px !important;
}