.sales-customer-info .custitle {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: capitalize;
}
.customerInfo-header label {
  font-size: 12px;
  color: #6a737b;
}
.customerInfo-header {
  margin-bottom: 10px;
}
.customerInfo-subTitle {
  display: flex;
}
.customerInfo-subTitle .MuiSvgIcon-root {
  margin-top: 5px;
}
.editClass .customerInfo-header .customerInfo-title,
.editClass .form-header .form-title {
  color: #6a737b;
  font-weight: 400;
}
.customerInfo-header input {
  font-weight: 600;
  font-size: 14px;
  color: #2a2f33;
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  margin-top: 5px;
  text-overflow: ellipsis;
}
input[type='checkbox'] {
  accent-color: #6a737b;
}
.customerInfo-header .editClass input {
  border: 1px solid rgb(205 205 205);
  padding: 5px;
}
.customerInfo-header .editClass :disabled {
  border: none;
}
.info-container .info-div {
  flex: 1;
  padding: 0 25px;
  border-right: 1px solid #e9ebec;
  /* display: flex;
    align-items: center; */
}
.editClass input {
  pointer-events: none;
  cursor: pointer;
  border: none;
  padding: 0px;
  color: #2a2f33;
  font-weight: 600;
  width: 100%;
  margin-top: 0px;
  text-overflow: ellipsis;
}
.full-screen-btn {
  position: relative;
  top: -4px;
  right: 8px;
}
.editClass .form-container {
  padding: 0;
}
.editClass .form-container2 {
  padding-left: 25px;
}
.info-container.editClass {
  pointer-events: none;
  cursor: default;
}
.onbording-form .MuiGrid-root{
  margin-left: 0;
}
.logo {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.viewAll {
  align-items: flex-start;
  display: flex;
  justify-content: end;
  margin-right: 16px;
}
.custitle-right {
  margin-right: 16px;
}
.viewDocument {
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #008cd2;
  text-align: left;
  letter-spacing: 0.4px;
  cursor: pointer;
}
.viewDocument1 {
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #008cd2;
  text-align: left;
  letter-spacing: 0.4px;
  cursor: pointer;
}
.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.info-div .form2 {
  font-size: 14px;
  width: 100%;
  margin-left: 0;
  color: #1b4f72;
}
.popup-title.billing {
  margin-left: 0;
  display: flex;
  color: #1b4f72;
}
.capitalize {
  text-transform: capitalize;
}
.billing label {
  width: 100%;
  display: flex;
  align-items: center;
  color: #2a2f33;
  font-size: 14px;
}
.billing input {
  width: auto;
}
.info-container .info-div:last-child {
  border: none; /* remove border ? */
}
.button-root:hover {
  background: none !important;
}
.customerInfo-header .invalid-feedback {
  color: red;
  margin-top: 3px;
}
.customerInfo-header input .MuiSvgIcon-root {
  margin-right: 5px;
}
.LocationProgressPopup .customerInfo-subTitle {
  pointer-events: auto;
  cursor: pointer;
}
.locationfields {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 20px;
}
.fields {
  color: #6a737b;
  height: 35px;
}
.values {
  font-weight: 600;
  font-size: 14px;
  color: #2a2f33;
  margin-top: 5px;
}
.values2 {
  font-weight: 600;
  font-size: 14px;
  color: #ff8a00;
  margin-top: 5px;
}
.values2 .viewDocument {
  pointer-events: all;
}
.values .viewDocument {
  pointer-events: all;
}
.locationfields > div {
  flex: 50%;
  margin-bottom: 30px;
}
.onbording-form .form2, .editClass .customer-form .form2 {
  font-size: 14px;
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 0px;
  margin-bottom: 6px;
  color: #008cd2;
  margin-top: -26px;
}
.editClass .popup-title.billing {
  margin-left: 7px;
  color: #1b4f72;
  padding-top: 0;
  margin-bottom: 10px;
}
.editClass .css-134fohp-MuiGrid-root > .MuiGrid-item {
  padding-left: 22px;
}
.editClass .form-header :disabled {
  background: none;
}

.form-title-width-onboarding {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 12px;
}