.mail-send-template{
    margin-bottom:16px;
}
.mail-send-tit{
    display: flex;
    align-items: center;
}
.mail-send-tit h4{
    margin-right: 10px;
}
.mail-send-tit a{
    color: #AAACAE;
    font-size: 14px;
}
.mail-top{
    width: 100%;
    height: 40px;
    background-color: #EDF6FC;
}
.mail-send-Table .MuiPaper-root{
    box-shadow: none !important;
    border: 1px solid #e4e5e6;
    border-top:none;
}
.mail-send-Table .MuiTableCell-head{
    background-color: #fafafa !important;
    color: #6A737B !important;
    font-size: 12px;
    border-top: 1px solid #E4E5E6;
    padding:6px 16px !important;
}
.mail-send-Table .MuiTableCell-body{
    font-size: 12px !important;
    color: #6A737B !important;
    padding:6px 16px !important;
}
.mail-send-Table .MuiTableBody-root .MuiTableRow-root:hover{
    background-color: #edf6fc;
}
.subject-cell{
    display: block;
    width: 418px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.send-date-cell,.send-time-cell{
    width:80px;
}
.subject-cell span.sub01{
    color: #2a2f33;
    font-weight: bold;
}
.file-lists-mailSend{
    position: relative;
    overflow: hidden;
}
.attchment-send-mail{
    overflow: hidden;
}
.file-lists-mailSend a.files-down-list,.attchment-send-mail a.files-down-list{
    display: block;
    float: left;
    width:156px;
    height:28px;
    border-radius:50px;
    border:1px solid #e4e5e6;
    font-size: 11px;
    color: #6a737b;
    text-align: center;
    line-height: 28px;
    margin-right:8px;
    margin-top:4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.more-email-attacment{
    position: absolute;
    display: block;
    width:28px;
    height: 28px;
    border-radius: 100%;
    border:1px solid #e4e5e6;
    text-align: center;
    line-height: 28px;
    right:6px;
    top:4px;
}
.file-lists-mailSend a:hover,.attchment-send-mail a:hover{
    border:1px solid #008cd2;
}
.mail-list .css-i4bv87-MuiSvgIcon-root{
    width: 15px !important;
    height: 15px !important;
    position: absolute;
    margin-top:-8px;
}
.mail-list .css-i4bv87-MuiSvgIcon-root:hover{
    color: #008cd2 !important;
}
.mail-details{
    position: relative;
    margin-top:16px;
}
.mail-top{
    position: relative;
}
.mail-top .arrbackIcon{
    color: #6A737B !important;
    position: absolute;
    top:50%;
    left:14px;
    width: 20px;
    height: 20px;
    margin-top:-10px;
}
.mail-tit,.send-mail-peo{
    justify-content: space-between;
}
.send-mail-peo{
    display: flex;
    padding-left:10px;
    align-items: flex-start;
}
.mail-tit,.mail-tit-left{
    display: flex;
    align-items: center;
}
.mail-tit-right{
    display: flex;
    align-items: center;
}
.mail-tit-time{
    font-size:12px;
    color: #AAACAE;
}
.mail-tit .css-i4bv87-MuiSvgIcon-root{
    color: #a1a1a1 !important;
    width: 16px !important;
    height: 16px !important;
    margin-left: 10px;
}
.mail-tit span{
    display: block;
    width:52px;
    height: 17px;
    border-radius: 4px;
    background-color: #e4e5e6;
    color: #6A737B;
    font-size: 11px;
    text-align: center;
    line-height: 17px;
    margin-left: 10px;
}
.mail-details .send-email-peo{
    padding-left:20px;
}
.mail-details .mail-body .mail-title{
    font-size: 20px;
}
.mail-details .mail-body .send-peo{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.mail-details .mail-body .send-peo span.sub01{
    font-weight: bold;
    font-size: 13px;
    margin-right: 8px;
    color: #2a2f33;
    margin-left:8px;
}
.mail-details .mail-body .send-peo span.sub02{
    color: #6a737b;
    font-size: 11px;
}
.mail-details .mail-body .for-peo{
    display: flex;
    align-items: center;
}
.mail-details .mail-body .for-peo .sub01{
    font-size: 11px;
    color: #6a737b;
}
.mail-details .mail-body .for-peo .css-i4bv87-MuiSvgIcon-root{
    font-size: 11px;
    color: #6a737b;
    width: 16px !important;
    height: 16px !important;
}
.mail-details .send-mail-body{
    padding-left:10px;
    margin-top:20px;
    font-size: 13px;
    color: #2a2f33;
    font-weight: 400;
    line-height: 1.5;
}
.mail-details .to-email-peo{
    margin-top:30px;
    border-top: 1px solid #e9ebec;
    padding-top: 30px;
}
.mail-details .peo-logo-name{
    display: flex;
    align-items: center;
}
.mail-details .peo-logo-name .peo-logo{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #6a737b;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    margin-right: 20px;
}
.mail-details .peo-logo-name .peo-name .for-peo-name{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.mail-details .peo-logo-name .peo-name .for-peo-name .sub01{
    font-size: 12px;
    color: #2a2f33;
    font-weight: bold;
    margin-right: 5px;
}
.mail-details .peo-logo-name .peo-name .for-peo-name .sub02{
    font-size: 11px;
    color: #6a737b;
}
.mail-details .peo-logo-name .peo-name .for-peo .sub01{
    font-size: 11px;
    color: #6a737b;
}
.mail-details .to-email-peo .send-mail-body{
    padding-left:60px;
}
.mail-top .reSendIcon{
    width: 20px;
    height: 20px;
    color: #6a737b;
    margin-right: 10px;
    position: absolute;
    right: 5px;
    top:10px;
}
.mail-top .css-i4bv87-MuiSvgIcon-root:hover{
    color: #008cd2;
}

.mail-reSend{
    position: relative;
    width: 100%;
    margin-top:20px;
    min-height: 188px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(106, 115, 123, 0.15);
    border: 1px solid #E9EBEC;
}
.mail-reSend-tit .css-i4bv87-MuiSvgIcon-root{
    width: 16px;
    height: 16px;
    color: #008cd2;
    position: absolute;
    top: 10px;
    left: 6px;
}
.mail-reSend-content{
    padding: 16px;
    padding-bottom:0px;
}
.mail-reSend-tit{
    display: flex;
    align-items: center;
}
.mail-reSend-tit span{
    font-size: 11px;
    color: #1c1b1f;
    margin-left:6px;
}
.mail-reSend-body{
    margin-top:16px;
    font-size: 12px;
    color: #2a2f33;
}
.mail-reSend-foot{
    position: absolute;
    left:0;
    right: 0;
    bottom: 0;
    height: 49px;
    border-top:1px solid #E9EBEC;
}
.mail-reSend-body textarea{
    width: 100%;
    height: 120px;
    border: none;
}
.mail-reSend .mail-template{
   margin:0px 16px;
   margin-right:30px;
}
.mail-reSend hr{
    display: none;
}
.mail-reSend .css-1pnmrwp-MuiTypography-root{
    font-size: 12px !important;
    color: #2a2f33;
}
.mail-reSend .css-1bkxqye-MuiButtonBase-root-MuiIconButton-root{
    font-size:12px !important;
    color: #2a2f33;
    margin-left:-5px;
}
.mail-reSend .css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size:12px !important;
}
.mail-list-more .mail-body{
   border-top:1px solid #E9EBEC;
}
.mail-reSend .mail-reSend-tit{

}
.mail-reSend-tit svg{
    width: 16px;
    height: 16px;
    color: #008cd2;
    position: absolute;
    top: 10px;
    left: 6px;
}