.number-field{
    width:15vw;
    
}
.customer-container{
  width: 100%;
  padding: 20px;
}
form p{
  font-family: MyriadPro,Serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.4px;
}
.input-text{
  font-size: smaller;
}
.faded-line {
    border: none;
    height: 0.1vw;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgb(212, 212, 212),
      rgb(255, 255, 255)
    );
    margin-bottom: 1.5vw;
  }