.bos-container {
    margin: 2vw;
  }
  
  .heading {
    margin-bottom: 3vw;
  }
  
  .select-ids {
    display: flex;
    gap: 25vw;
    /* margin-bottom: 1.2vw; */
  }
  
  .select-site-ids {
    border-radius: 0.8vw;
    margin-bottom: 3vw;
    display:flex;
  
  }
  .concept-box{
     display: "flex";
    flex-direction: "column";
  }
  
  .field-text {
    font-size: 1vw;
  }
  
  .switch-line1 {
    display: flex;
    gap: 2.5vw;
    margin-bottom: 3vw;
  }
  
  .switch {
    display: flex;
    align-items: center;
  }
  
  .switch-line2 {
    display: flex;
    gap: 2.5vw;
    margin-bottom: 3vw;
  }
  
  .submaintenance-cutoff {
    display: flex;
    align-items: center;
    gap: 0.5vw;
  }
  
  .faded-line {
    border: none;
    height: 0.1vw;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgb(212, 212, 212),
      rgb(255, 255, 255)
    );
    margin-bottom: 1.5vw;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 2.5vw;
  }
  
  .no-text {
    opacity: 0.5;
    font-size: 1vw;
  }
  
  .button {
    margin-right: 3vw;
  }
  