.sendename{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-family: MyriadPro,Serif;
}
.sendertime{
  color: #424242;
  font-weight: 400;
}
.replyChat{
  background: #fafafa;
  border: 0.1rem solid #ebebeb;
  border-top: 0;
  border-radius: 0 0 0.4rem 0.4rem;
  height: 2.8rem;
  overflow: hidden;
  font-family: MyriadPro,Serif;
  padding: 0 0 0 0.8rem;
  position: relative;
  text-align: left
}
.senderMessage{
  color: gray;
}
.chatBox:last-child{
  margin-bottom: 5vw;
}
.chatText,.chatText:active{
  border: none;
  background: #fafafa;
  border-bottom: #008cd2;
  width: 100%;
  height: 60%;
  overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-family: MyriadPro,Serif;
    letter-spacing: 0.4px;
}

.texst-box{
  display:flex;
  flex-direction:row;
  width: 98%;
}

.texst-box input:focus {
  outline: none;
}
.activeReplyChat{
  border-bottom: 3px solid #008cd2;
}