
.top{

    margin-top: 25px;
    box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
}
.bottom{
    height: auto;
    margin-top: 16px;
    box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
    display: flex;
    flex-direction: column;
    width: 100%
}
.bottom .goal-stages .MuiPaper-root{
  display: flex;
  align-items: center;
  text-align: center;
  font-family:MyriadPro,Serif;
  color: #2A2F33;
  letter-spacing: 0.6px;
  justify-content: center;
}
.Header{
   font-weight:600;
    white-space: nowrap;
    overflow: hidden;
    height: 29px;
    width: 288px;
    font-size: 24px;
    margin-top: 0px;
    color: #2A2F33;
  }
  .bottom-heading{
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .restrictionMes{
    color: red;
    font-size: 16px;
    margin-left:20px;
  }
  .mandatoryFileMsg{
    color: orange;
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
  .goal-stages .customerdetails{
    font-size: 14px;
    box-sizing: border-box;
    margin-right: 20px;
    margin-top: 20px;
    float:left;
    width: 120px;
    height: 145px;
    background: #FFFFFF;
    border: 1px solid rgba(170, 172, 174, 0.5);
    border-radius: 4px;
    cursor: pointer;
  }
  .goal-stages .completed .checkCircle {
  position: absolute;
  color: #fff;
  }

  .goal-stages .completed .checkCircle-icon{
    display: flex;
    justify-content: right;
    margin: 5px;
  }
  .goal-stages .completed .checkCircle-text{
    display: flex;
    height: inherit;
    margin-top: -10px;
  }
 .goal-stages .customerdetails:hover,
 .goal-stages .completed,
 .goal-stages .selected{
    font-size: 14px;
    box-sizing: border-box;
    margin-right: 20px;
    margin-top: 20px;
    float:left;
    width: 120px;
    height: 145px;
    background-color: #1B4F72;
    border: 1px solid rgba(170, 172, 174, 0.5);
    border-radius: 4px;
  }
  .goal-stages .customerdetails:hover .MuiCardContent-root,
  .goal-stages .completed .MuiCardContent-root,
  .goal-stages .selected .MuiCardContent-root{
    color: #FFFFFF;
  }
  .goal-stages .completed .MuiCardContent-root{
    display: flex;
    align-items: center;
  }
  .businessevent{
    font-size: 14px;
    float:left;
    box-sizing: border-box;
    margin-top: 40px;
    width: 120px;
    height: 145px;
    background: #FFFFFF;
    border: 1px solid rgba(170, 172, 174, 0.5);
    border-radius: 4px;
  }
  .MuiPaper-root .logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
  .cuslogo{
    height: 50px;
    width: 50px;
    background: #6A737B;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 12px;
    justify-content: center;
    color: #FFFFFF;
  }

  .progressbar-card{
    font-family: MyriadPro,Serif;
  }
  .progressbar-card .MuiCardContent-root {
    margin-left: 20px;
}
  .progressbar-header{
    color: black;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    font-family: MyriadPro,Serif;
  }
  .document-upload{
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }

  .document-upload .MuiGrid-root {
    box-shadow: none;
 }
 .bottom .goal-stages .completed{
  display: block ;
}
.bottom .goal-stages .disabled {
  color: #AAACAE;
  cursor: none;
}
.bottom .goal-stages:hover .disabled {
  background: #FFFFFF;
}
.bottom .goal-stages:hover .disabled .MuiCardContent-root{
  color: #AAACAE;
}

