.mail-template{
    font-family: MyriadPro,Serif;
    margin: 40px;
    margin-left: 25px;
}
.css-ittuaa-MuiInputAdornment-root{
    width: 70px;
}
.mail-template .send-button:disabled{
    color: white;
    background-color: #008CD2;
    border-radius: 30px;
}
.MuiBox-root .skip-buttons-1 {
    width: 100%;
    color: #a1a1a1;
    border: 1px solid #a1a1a1;
    font-family: MyriadPro,Serif;
    text-transform: none;
    background-color: #fff;
    font-size: 1.125rem;
    line-height: 1.125rem;
    padding: 0.5rem 1rem;
    border-radius: 1.125rem;
  }