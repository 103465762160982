.upload-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-left: 20px;
}
.view {
  margin-left: 10px;
  margin-top: 10px;
  color: #56b146;
  font-weight: 400;
  font-size: 18px;
}
.view1 {
  margin-left: 10px;
  margin-top: 10px;
  color: #ec0a05;
  font-weight: 400;
  font-size: 18px;
}
.button-wrapper .MuiBox-root .skip-buttons-1 {
  width: 100%;
  color: #a1a1a1;
  border: 1px solid #a1a1a1;
  font-family: MyriadPro,Serif;
  text-transform: none;
  background-color: #fff;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.5rem 1rem;
  border-radius: 1.125rem;
}
.mail-send-tem{
  padding-left:16px;
  padding-right: 16px;
}
.Select-flex-tt{
  position: relative;
}
.show-upload-list{
  position: absolute;
  right:30px;
  top:30px;
  left:300px;
}
.show-upload-list .MuiPaper-root{
  box-shadow: none !important;
  border: 1px solid #e4e5e6;
  border-top:none;
}
.show-upload-list .MuiTableCell-head{
  background-color: #fafafa !important;
  color: #6A737B !important;
  font-size: 12px;
  border-top: 1px solid #E4E5E6;
  padding:6px 16px !important;
}
.show-upload-list .MuiTableCell-body{
  font-size: 12px !important;
  color: #2a2f33 !important;
  padding:6px 16px !important;
}
.show-upload-list .MuiTableBody-root .MuiTableRow-root:hover{
  background-color: #edf6fc;
}
.show-upload-list .sls-icon{
  font-size:18px;
  color: #6A737B;
  margin-right: 10px;
}
.masrsLink{
  color:#1976d2;
  display: block;
  margin-top: 12px;
  margin-left: 32px;
}
/* .other_doc_name label{
  font-size: 12px;
  left: 18px;
}
.other_doc_name .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 12px;
  top: 28px;
}
.other_doc_name .css-1wc848c-MuiFormHelperText-root{
  margin-top:-18px;
  margin-left: 32px;
}
.other_doc_name .css-wgai2y-MuiFormLabel-asterisk {
  color: red;
} */
.upload-collapse-table .MuiTableCell-body{
  padding:0 !important;
}
.upload-collapse-table .MuiTableCell-head{
  padding-top:6px !important;
  padding-bottom:6px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}