.AccountOnBoard{
    background: none !important;
    box-shadow: none !important;
    text-align: center;
}
.AccountOnBoard img{
    width: 230px;
    margin-bottom: 15px;
}
.AccountOnBoard h2{
font-family: MyriadPro,Serif;
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height:52px;
text-align: center;
margin: 10px 0px;
color: #008CD2;
}
.AccountOnBoard p{
 font-family: MyriadPro,Serif;
font-style: normal;
font-weight: 600;
font-size: 18px;
text-align: center;
margin-top: 20px;
color: #2A2F33;

}
.AccountOnBoard #secondary{
    font-size: 14px;
    padding: 6px 24px;
    margin-top: 45px;

}
  .confetti {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
   
  }
  
  .confetti-piece {
    position: absolute;
    width: 6px;
    height: 10px;
    background: #FBBA23;
    top: -10px;
    opacity: 0;
  }
  .confetti-piece:nth-child(1) {
    left: 7%;
    transform: rotate(85deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 241ms;
    animation-duration: 540ms;
  }
  .confetti-piece:nth-child(2) {
    left: 14%;
    transform: rotate(16deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 199ms;
    animation-duration: 691ms;
  }
  .confetti-piece:nth-child(3) {
    left: 21%;
    transform: rotate(-74deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 252ms;
    animation-duration: 501ms;
  }
  .confetti-piece:nth-child(4) {
    left: 28%;
    transform: rotate(21deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 269ms;
    animation-duration: 502ms;
  }
  .confetti-piece:nth-child(5) {
    left: 35%;
    transform: rotate(20deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 303ms;
    animation-duration: 411ms;
  }
  .confetti-piece:nth-child(6) {
    left: 42%;
    transform: rotate(-79deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 192ms;
    animation-duration: 664ms;
  }
  .confetti-piece:nth-child(7) {
    left: 49%;
    transform: rotate(172deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 135ms;
    animation-duration: 601ms;
  }
  .confetti-piece:nth-child(8) {
    left: 56%;
    transform: rotate(28deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 37ms;
    animation-duration: 363ms;
  }
  .confetti-piece:nth-child(9) {
    left: 63%;
    transform: rotate(166deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 309ms;
    animation-duration: 647ms;
  }
  .confetti-piece:nth-child(10) {
    left: 70%;
    transform: rotate(-80deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 225ms;
    animation-duration: 451ms;
  }
  .confetti-piece:nth-child(11) {
    left: 77%;
    transform: rotate(25deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 51ms;
    animation-duration: 622ms;
  }
  .confetti-piece:nth-child(12) {
    left: 84%;
    transform: rotate(176deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 73ms;
    animation-duration: 685ms;
  }
  .confetti-piece:nth-child(13) {
    left: 91%;
    transform: rotate(-91deg);
    animation: makeItRain 700ms infinite ease-out;
    animation-delay: 59ms;
    animation-duration: 386ms;
  }
  .confetti-piece:nth-child(odd) {
    background: #65BB5C;
  }
  .confetti-piece:nth-child(even) {
    z-index: 1;
  }
  .confetti-piece:nth-child(4n) {
    width: 5px;
    height: 13px;
    animation-duration: 1400ms;
    background: #F23B14;
  }
  .confetti-piece:nth-child(3n) {
    width: 5px;
    height: 13px;
    animation-duration: 1750ms;
    animation-delay: 700ms;
  }
  .confetti-piece:nth-child(4n-7) {
    background: #2A2F6A;
  }
  
  @keyframes makeItRain {
    from {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    to {
      transform: translateY(350px);
    }
  } 