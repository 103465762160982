.vpna-mail-top{
    width: 100%;
    height: 20px;
}
.vpna-mail-top .arrbackIcon{
    color: #6A737B !important;
    /* position: absolute;
    top:50%;
    left:14px; */
    width: 20px;
    height: 20px;
    /* margin-top:-10px; */
}