.step-label{
    font-size: 16px;
    font-style: normal;
    font-family: MyriadPro,Serif;
    letter-spacing: 0.4px;
}

.step-label-completed{
    color: #008CD2;
    font-weight: 700;
}
.Mui-active .step-label-pending{
    color: #008CD2;
}
.Mui-active .step-label-pending .MuiChip-outlined{
    background: #E5F3FA;
    color: #008CD2;
}
.step-label-pending{
    color: #2A2F33;
    font-weight: 500;
}

.step-label-active{
    color: #008CD2;
    font-weight: 500;
    cursor: pointer;
}
.MuiStepConnector-line {
    border-color: #008CD2;
}
.MuiStepConnector-vertical.Mui-completed .MuiStepConnector-line,
.MuiStepConnector-vertical.Mui-active .MuiStepConnector-line{
    border-color: #008CD2 !important;
    border-left-width: 2px;
}