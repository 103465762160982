.accordion {
    margin-top: 0px;
    background-color: #FAFAFA;
}
.accordion-container-accept .MuiAccordion-root {
    width: 100%;
    height: auto !important;
    border-top: solid 1px #E4E5E6;
    box-shadow: none;
  }
  .accordion-container-accept .MuiTableContainer-root{
    box-shadow: unset;
  }
  .unset-border-bottom{
    border-bottom: unset !important;
  }
  .accordion-container-accept .MuiAccordionDetails-root{
    padding: 6px 16px 6px 16px !important;
  }