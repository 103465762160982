.customer-form .form-buttons {
    justify-content: end;
    padding: 10px 15px 10px 10px;
  }
  .customerInfo-header .editClass input {
    border: 1px solid #e4e5e6;
    padding: 8px 10px;
    border-radius: 4px;
}
.div-separator {
    flex: 1 1;
    padding: 0;
    border-right: 1px solid #E9EBEC;}
    .div-separator:last-child {
        border-right: none;
    }
.css-hlj6pa-MuiDialogActions-root.customer-form{
padding: 0px;
}

.customerInfo-header .editClass input:focus-visible{
 outline: solid 1px #008CD2;

}
.updateIcon .MuiButton-startIcon > svg{background: #fff;
    color: #008CD2;
    border-radius: 50%;
    font-size: 17px;
}
.customerInfo-header .editClass :disabled {
    border: none;
    background: #eee;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root.update-customer-title{
    font-size: 18px;
    padding: 12px 20px;
    border-bottom: solid 1px #eee
}
.css-csmqbv-MuiPaper-root-MuiDialog-paper{
    max-width: 1200px !important;
}
input[type="checkbox"] {
    accent-color: #008CD2;
}
.update-customer-main .popup-title{margin-bottom:5px;position: relative;}
.popup-content{padding-right:30px;}
.popup-content .MuiGrid-root{margin-left: 0px;} 
.popup-content  .info-div { padding: 0 25px 0px 0px;}
.update-customer-title .closeDialog  {  top: 5px;  }

.info-container #segment{
    pointer-events: all;
}
/* .popup-content.editClass input{
border: 1px solid #e4e5e6;
} */
.popup-content .form-header :disabled {
    border: none;
    background: #eee;
}
.popup-content input:focus-visible{
    outline: solid 1px #008CD2;
   
   }
.popup-content .popup-title{
    font-size: 14px;
    margin-left: 13px;
    padding-left: 10px;
    padding-top:0px;
    margin-bottom: 8px;
    color: #008CD2;
}
.popup-content .popup-title.billing{
    padding: 0;
    margin-left: 20px;
}
.popup-content .form-header .form-title {
    font-weight: 600;
    color: #2a2f33;
}
.popup-content input {
    padding:8px;
    color: #6A737B;
    font-weight:normal;
    border: 1px solid #e4e5e6;
    margin-top: 6px;
    width: 90%;
    
  }
.popup-content  .popup-title.billing label input{
    width: auto;

  }
  .popup-content .css-13cymwt-control{
    margin-top: 5px;

  }
  /* .info-container.editClass-modal input{
    pointer-events:all
} */
.info-container.editClass-modal .form-container {
    padding: 0px;
  }