.progress-table thead th{
    font-family: MyriadPro,Serif;
    font-Weight:400;
    font-Size:14px;
    Line-height:15px;
    color:#6A737B;
    background: #FAFAFA;
    padding: 14px 12px;
    vertical-align: middle;
    
}
.progress-table thead th:last-child{
    Padding-right: 17px;
    text-align: right;
}
.progress-table tbody tr td{padding: 12px 15px;
    vertical-align: middle;
    line-height: 15px;

}
.progress-table tbody tr th{padding: 12px 15px;
    vertical-align: middle;
    line-height: 15px;

}
.disabled-button{
    background-color: #d9d9d9 !important;
}