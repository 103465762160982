.header {
    position: absolute;
    padding: 10px;
    display: flex;
    color: #2A2F33;
    font-feature-settings: 'clig' off, 'liga' off;
    /* 14px Small Header */
    font-family: MyriadPro,Serif;
    font-size: 14px;
    justify-content: space-between;
    z-index: 999;
    box-sizing: border-box;
    width: 100%;
    font-weight: bold;
}

.relation {
    display: flex;
    align-items: center;
}

.relationItem {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

.statusIcon {
    display: block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}
