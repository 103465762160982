
.toolbar-root{
    min-height : 50;
}
.product-name{
    font-size: 26px;
    font-family:Arial, Helvetica, sans-serif;
    width:46px;
    margin-top: 10px;
}
.search-name .MuiOutlinedInput-notchedOutline{
    border: none;
}
.search-name label.Mui-focused{
    display: none;
}
.css-1gtpq4r-MuiButtonBase-root-MuiIconButton-root{
    padding: 3px 0px 0px 2px !important;
}
.search-name .MuiInputBase-root{
    padding-left: 0px !important;
}

 .MuiAutocomplete-popper div{
    padding: 5px;
    margin-left: 10px;
    text-decoration: none;
    color: #008cd2;
}
.MuiAutocomplete-popper a{
    text-decoration: none;
}
.notification-icon-box{
    background-color: #007EBD;
    width:auto;
}
.MuiIconButton-sizeMedium.user-box{
   
    width:auto;
    font-size: 18px;
    color: white;
    font-family: MyriadPro,Serif;
    letter-spacing: 0.2px;
}
.MuiIconButton-sizeMedium.user-box .user-icon{
    font-size: 32px
}
.user-box{
   
    width:44px;
    height:44px;
}
.notification-icon{
    color:white;
    width: 20px;
    height:20px;
}
.user-icon{
   
    width: 20px;
    height:20px;
}
.input.input-md {
 
    height: 32px;
    border-radius: 16px;
    width:20.75rem;

   
}
