.omcc-tit{
    display: block;
    padding-top:20px;
    padding-bottom:20px;
    padding-left:20px;
}
.date-time-sec{
    padding-left:20px;
}
.time-picker-sec{
    margin-left:16px !important;
}
.date-time-footer button{
    border-radius: 20px !important;
    background-color: #fff;
    border: 1px solid #1976d2;
    color: #1976d2;
}
.date-time-footer button:hover{
    color: #fff;
}
.date-time-footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #ececec;
    margin-top:20px;
}
.Date-Time-template{
    padding-right: 20px;
}
.show-time{
    padding-left:20px;
    padding-top:20px;
    padding-bottom:20px;
}
/* .Mui-error{
    color: #2a2f33 !important;
} */
/* .Mui-error .MuiOutlinedInput-notchedOutline{
    border:1px solid #aaacae !important;
} */