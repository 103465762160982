.filter-item {
  width: 1250px;
  /* height: 50px; */
  justify-content: space-between;
  display: flex;
  padding-top: 10px;
  padding-left: 5px;
  flex-wrap: wrap;
}

.filter-margin{
  margin-top: 16px!important;
  margin-right: 1.2%!important;
  width: 13%;
}

.filter-contanier-input label,.filter-contanier-input input,#menu-totalCompletion li,#select-label,
#menu-status li,#menu-segment li,#menu-customerType li,.datepicker label,.datepicker input{
    font-size: 12px;
    font-weight: 400;
}
.flex {
    display: flex;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .align-items-center {
    align-items: center;
  }
  .form-btn{
    /* margin-left: 8px;
    margin-top: 10px; */
  }
  .datepicker{
    display: flex;
  }
  .datepicker-to{
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
  };