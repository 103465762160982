.customer-form .form-div {
  display: flex;
  gap: 20px;
  padding-right: 10px;
  margin-left: auto;
}
.customer-form .form-buttons {
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
}
.create_customer{
  overflow: auto !important;
}
.closeDialog svg{
  font-size: 20px;
}
#primary {
  font-family: MyriadPro,Serif;
  text-transform: none;
  background-color: #008cd2;
  color: #fff;
  border: none;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.5rem 1rem;
  border-radius: 1.125rem;
}
#secondary {
  font-family: MyriadPro,Serif;
  text-transform: none;
  background-color: #fff;
  color: #008cd2;
  border: 1px solid #008cd2;
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 0.5rem 1rem;
  border-radius: 1.125rem;
}
.form-container {
  padding: 0 10px 10px;
}
.form-header label {
  font-size: 12px;
  color: #6a737b;
}
.form-header {
  margin-bottom: 10px;
}
.form-subTitle .address {
  width: 95%;
}
.form-subTitle .required {
  margin-top: 3px;
  font-size: 12px;
  color: #6a737b;
}

.form-subTitle .goLiveDate-required {
  color: #008cd2;
  font-family: MyriadPro,Serif;
  margin-top: 5px;
  font-size: 12px;
}
.stepper-section {
  margin: 0 20%;
  padding: 20px;
}
.form-header :disabled {
  border: none;
}
.form-header .is-invalid {
  border-color: red;
}

.form-header input {
  /* margin-top: 5px; */
  height: 20px;
  background: #ffffff;
  border: 1px solid #e4e5e6;
  border-radius: 3px;
  color: #2a2f33;
  font-family: MyriadPro,Serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 5px;
  width: 95%;
  min-height: 25px;
}
.form-header input:focus-visible{
  outline:#008cd2 solid 1px;
}
.form-header .form-title {
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2a2f33;
  /* margin-bottom: 8px; */
}
.form-header .invalid-feedback {
  color: red;
  margin-top: 3px;
}
.popup-title {
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #2a2f33;
  /* margin-left: 20px; */
  display: flex;
  justify-content: flex-start;
  padding: 15px 15px;
}
.customer-form .form2 {
  font-size: 16px;
  padding-left: 10px;
  padding-top: 15px;
}
.customer-form .billing {
  font-size: 14px;
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 15px;
  color: #2a2f33;
}
.customer-form .billing label {
  cursor: pointer;
}
.customer-form .documentUploadClass .notfileUpload,
.customer-form .documentUploadClass .fileUpload {
  max-width: 100%;
  height: 125px;
  width: 480px;
}
.customer-form .document-select {
  padding: 0 20px;
}
.customer-form .accordion-container .MuiAccordion-root {
  width: 100%;
  height: 50px;
  border-top: solid 1px #E4E5E6;
  box-shadow: none;
}
.accordion-container{
  margin-top: 20px;

}
.form-header .is-invalid,
.required .is-invalid {
  color: red;
  font-weight: 400;
}
.react-datepicker__week .react-datepicker__day--today,
.react-datepicker__week .react-datepicker__day--today:hover {
  background: #008cd2;
  color: white;
  border-radius: 50%;
  font-size: 14px;
}
.react-datepicker__day {
  font-family: MyriadPro,Serif;
}
.showAccountOwner{
  display: flex;
}
.AccountCircleOutlinedIcon{
  align-self: center;
  margin-right: 3px;
}

.form-cont{
  position: relative;
}
.loading-overlay{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.customer-form .form-buttons button{
  padding: 5px 20px !important;
  font-size: 16px !important;
}
.form-header .MuiFormControlLabel-label{
  font-family: MyriadPro,Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2a2f33;
}
.customer-form .warning-msg{
  color: orange;
  font-size: 12px;
}

