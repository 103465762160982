.toptwo {
  margin-top: 25px;
  box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
  border: 0.3px #cecece solid;
  width: 32.19vw;
  height: 64px;
}

.icon-container {
  margin-top: 20px;
}

.request_heading {
  font-weight: 600;
  font-size: 14px;
  color: #2a2f33;
  font-family: MyriadPro,Serif;
}

.request_subheading {
  font-size: 8px;
  color: #a1a1a1;
  font-family: MyriadPro,Serif;
  font-weight: 400;
}

.request_button {
  padding: 12px;
  height: 8px;
  width: 60px;
  border: 1px solid #008cd2;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #008cd2;
  font-family: MyriadPro,Serif;
}

.sub_main {
  display: flex;
}
