.PictureIcon{
    background: rgba(0, 140, 210, 0.1);
    height: 70px;
    width: 70px;
    color: #008cd2;
    border-radius: 50%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.UploadPictureMain .notfileUpload{
    width: 100% !important;
    height: 200px !important;
}
 .documentUploadClass.UploadPictureMain .notfileUpload:hover{
    background: none;
}
.UploadPictureMain .popup-container {
    padding: 0px 10px 150px 10px;
}
.error{
    color: red;
  font-weight: 400;
  font-size: 18px;
 
}
.img-notfileUpload{
    height: auto;
    max-width: 100%;
    overflow: auto;
}