.documentUploadClass .MuiPaper-root{
    background: #FFFFFF;
    border: 1px dashed #AAACAE;
    box-shadow: 1px 1px 4px rgb(106 115 123 / 15%);
    border-radius: 8px;
    width:240px;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.documentUploadClass {
    margin: 10px;
}

.documentUploadClass .BackupOutlinedIcon{
    color: #AAACAE;
    font-size: 34px;
}
.documentUploadClass .notfileUpload:hover .BackupOutlinedIcon{
color: #008CD2;
}
.unsupported{
    color:red;
    font-size: 18px;
    font-weight: 400;
}
.documentUploadClass .CloudDoneRoundedIcon{
    color: #56B146;
    font-size: 34px;
}
.uploadIcon b{ 
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #008CD2;
}
.documentUploadClass  .notfileUpload{
    cursor: pointer;
}
.documentUploadClass .notfileUpload:hover{
background: rgba(0, 140, 210, 0.1);
border: 1px dashed #008CD2;
box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
}
.documentUploadClass .uploadFilename{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #008CD2;
}
.uploadFileDisplay .MuiSvgIcon-root{
    line-height: 14px;
    font-size: 14px;
    margin: 10px;
    cursor: pointer;


}
.download{
    display: flex;
    align-items: center; 
    cursor: pointer;
}
.downloadtext{
color: #008CD2;
margin: 0;
}
.helpertext{
    display:flex;
    margin-top: 5px; 
    justify-content: space-between;
    align-items: center;
}
.helpertext.isMSR{
    justify-content:flex-start !important;
}

.upload-card-container{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    gap:20px;
}