.buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.buttonGroup button {
    text-transform: none;
    margin-left: 10px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 18px;
}